import client from "./Client";

const getClasses = (schoolId, token) =>
  client.get(`/staff/school/${schoolId}/classes`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const postionClass = (
  school_id,
  class_id,
  session_id,
  term_id,
  subjects_to_assess,
  token
) =>
  client.post(
    "/staff/class/assessment/position",
    {
      class_id,
      session_id,
      term_id,
      subjects_to_assess,
      school_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getSingleClass = (class_id, school_id, token) =>
  client.get(`/staff/school/${school_id}/class/${class_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getNumOfStudents = (class_id, school_id, token) =>
  client.get(`/staff/school/${school_id}/stat/class/${class_id}/students`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getNumOfClassSubjects = (class_id, school_id, token) =>
  client.get(`/staff/school/${school_id}/stat/class/${class_id}/subjects`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getClassAssessmentPercentage = (class_id, school_id, token) =>
  client.get(`/staff/school/${school_id}/stat/class/${class_id}/assessment`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getStudentsInClassPerformance = (
  class_id,
  school_id,
  termId,
  sessionId,
  limit = null,
  token
) => {
  const params = {};

  if (termId) {
    params.termId = termId;
  }

  if (limit) {
    params.limit = limit;
  }

  if (sessionId) {
    params.sessionId = sessionId;
  }

  return client.get(
    `/staff/school/${school_id}/class/${class_id}/performance/students`,
    {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

const getNumberOfStudentsByGender = (
  class_id,
  school_id,
  termId,
  sessionId,
  token
) => {
  const params = {};

  if (termId) {
    params.termId = termId;
  }

  if (sessionId) {
    params.sessionId = sessionId;
  }

  return client.get(
    `/staff/school/${school_id}/stat/class/${class_id}/gender/students`,
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

const getSubjectsInClass = (class_id, school_id, limit, token) => {
  const params = {};
  if (limit) {
    params.limit = limit;
  }
  return client.get(`/staff/school/${school_id}/class/${class_id}/subjects`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export default {
  getClassAssessmentPercentage,
  getNumOfClassSubjects,
  getNumOfStudents,
  getClasses,
  getSingleClass,
  getStudentsInClassPerformance,
  getNumberOfStudentsByGender,
  getSubjectsInClass,
  postionClass,
};
