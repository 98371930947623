import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useNavigate, useLocation } from "react-router-dom";
import {
  empty,
  findGrade,
  prepareResponseData,
  reIndex,
} from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import _ from "lodash";

// css
import "../students/Students.css";
import "./Assessment.css";

// api
import studentApi from "../../api/Student";
import subjectApi from "../../api/Subject";
import classApi from "../../api/Classes";
import assessmentApi from "../../api/Assessment";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import StudentsTable from "../../components/tables/primeTable/students/StudentsTable";
import { Form, Formik } from "formik";
import InputField from "../../components/form/InputField";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import * as Yup from "yup";
import AssessmentTable from "../../components/tables/AssessmentTable";
import AssessmentTableHeader from "../../components/tables/AssessmentTableHeader";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const validationSchema = Yup.object().shape({
  ca1: Yup.string().max(2).optional(),
  ca2: Yup.string().max(2).optional(),
  exam: Yup.string().max(2).optional(),
});

const validationCommentSchema = Yup.object().shape({
  comment: Yup.string().required("This field is required"),
});

const RecordAssessment = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const paramState = !empty(location.state) ? location.state : {};
  const classId =
    !empty(paramState) && !empty(paramState.classId) ? paramState.classId : "";
  const sessionId =
    !empty(user) && !empty(user.sessionId) ? user.sessionId : "";
  const termId = !empty(user) && !empty(user.termId) ? user.termId : "";
  const subjectId =
    !empty(paramState) && !empty(paramState.subjectId)
      ? paramState.subjectId
      : "";
  //ref
  const toastTR = useRef(null);
  // states
  const [studentData, setStudentData] = useState([]);
  const [subjectData, setSubjectData] = useState({});
  const [classData, setClassData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [search, setSearch] = useState("");
  const [studentAssessment, setStudentAssessment] = useState([]);
  const [reIndexedSubjects, setReIndexedSubjects] = useState({});
  const [isStudentDialog, setIsStudentDialog] = useState(false);
  const [activeDetails, setActiveDetails] = useState({});
  const [studentReportComment, setStudentReportComment] = useState("");
  const [selectedStudentTotal, setSelectedStudentTotal] = useState(0);
  const [initialValues, setInitialValues] = useState({
    ca1: 0,
    ca2: 0,
    exam: 0,
  });

  const currentSessionId =
    !empty(user) && !empty(user.currentSessionId) ? user.currentSessionId : "";
  const currentTermId =
    !empty(user) && !empty(user.currentTermId) ? user.currentTermId : "";
  const principalComments =
    !empty(user) && !empty(user.schoolPrincipalComments)
      ? user.schoolPrincipalComments
      : [];
  const headMasterMistressComments =
    !empty(user) && !empty(user.schoolHeadComments)
      ? user.schoolHeadComments
      : [];
  const currentTermTitle =
    !empty(user) && !empty(user.currentTermTitle) ? user.currentTermTitle : "";
  const currentSessionTitle =
    !empty(user) && !empty(user.currentSessionTitle)
      ? user.currentSessionTitle
      : "";
  useEffect(() => {
    // fetch student
    try {
      if (!empty(classId) && !empty(subjectId)) {
        getStudents();
        getSubjectDetails();
        getClassDetails();
        getSubjectsInClass();
      } else {
        navigate("/404");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load student. Please try again later."
      );
    }
  }, [first, rows, search, classId]);

  // function to get all student
  const getStudents = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const page = first / rows;
      let response;
      response = await studentApi.getStudentsInClass(
        schoolId,
        page,
        rows,
        search,
        classId,
        "assessment",
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudentData([]);
      } else {
        setStudentData(response_data.response);
        setTotalRecords(!empty(response_data.count) ? response_data.count : 0);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjectDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await subjectApi.getSingleSubject(
        subjectId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setSubjectData({});
      } else {
        setSubjectData(response_data.response);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    }
  };

  const getClassDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSingleClass(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setClassData({});
      } else {
        setClassData(response_data.response);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    }
  };

  const getSubjectsInClass = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSubjectsInClass(
        classId,
        schoolId,
        1000,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setReIndexedSubjects({});
      } else {
        const classSubjects = response_data.response;
        const reIndexedSubjects = reIndex(classSubjects, "_id");
        setReIndexedSubjects(reIndexedSubjects);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const handleSubmit = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(currentSessionId)) {
        return responseDailog(
          "error",
          "Session not set",
          "You cannot complete this operation if session is not set. Refresh page / try again later"
        );
      }
      if (empty(currentTermId)) {
        return responseDailog(
          "error",
          "Term not set",
          "You cannot complete this operation if term is not set. Refresh page / try again later"
        );
      }
      if (empty(values)) {
        return responseDailog(
          "error",
          "Something went wrong",
          "Please refresh page / try again later."
        );
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(activeDetails) && !empty(activeDetails._id)
          ? activeDetails._id
          : "";
      const ca1 = !empty(values.ca1) ? values.ca1 : "";
      const ca2 = !empty(values.ca2) ? values.ca2 : "";
      const exam = !empty(values.exam) ? values.exam : "";
      const response = await assessmentApi.recordAssessment(
        schoolId,
        studentId,
        ca1,
        ca2,
        exam,
        currentSessionId,
        currentTermId,
        subjectId,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      } else {
        setIsStudentDialog(false);
        await getStudents();
        return responseDailog("success", "Success", "Operation successful");
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCommentSubmit = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(activeDetails) && !empty(activeDetails._id)
          ? activeDetails._id
          : "";
      const comment = !empty(values.comment) ? values.comment : "";
      const response = await assessmentApi.addStudentPerformanceComment(
        schoolId,
        studentId,
        sessionId,
        termId,
        comment,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      } else {
        responseDailog("success", "Success", "Comment added successfully.");
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const openAssessmentModal = (data) => {
    assessmentData(data, "assessment");
    setIsStudentDialog(true);
  };

  const assessmentData = (data) => {
    const studentAssessmentData =
      !empty(data) && !empty(data.assessment) ? data.assessment : [];
    const studentReport =
      !empty(data) && !empty(data.reports) ? data.reports : [];
    const currentStudentAssessmentData = studentAssessmentData.filter(
      (item) => {
        const itemSessionId =
          !empty(item) && !empty(item.sessionId) ? item.sessionId : "";
        const itemTermId =
          !empty(item) && !empty(item.termId) ? item.termId : "";
        const subjectId =
          !empty(item) && !empty(item.subjectId) ? item.subjectId : "";
        return (
          itemSessionId === currentSessionId &&
          itemTermId === currentTermId &&
          !empty(reIndexedSubjects[subjectId])
        );
      }
    );
    const reIndexedAssessment = reIndex(
      currentStudentAssessmentData,
      "subjectId"
    );
    const currentSubject =
      !empty(reIndexedAssessment) && !empty(reIndexedAssessment[subjectId])
        ? reIndexedAssessment[subjectId]
        : {};
    const ca1 =
      !empty(currentSubject) && !empty(currentSubject.ca1)
        ? currentSubject.ca1
        : 0;
    const ca2 =
      !empty(currentSubject) && !empty(currentSubject.ca2)
        ? currentSubject.ca2
        : 0;
    const exam =
      !empty(currentSubject) && !empty(currentSubject.exam)
        ? currentSubject.exam
        : 0;
    setInitialValues({
      ca1,
      ca2,
      exam,
    });

    let comment = "";
    for (let i = 0; i < studentReport.length; i++) {
      const itemSessionId =
        !empty(studentReport[i]) && !empty(studentReport[i].sessionId)
          ? studentReport[i].sessionId
          : "";
      const itemTermId =
        !empty(studentReport[i]) && studentReport[i].termId
          ? studentReport[i].termId
          : "";
      if (itemSessionId === currentTermId && itemTermId === currentTermId) {
        comment =
          !empty(studentReport[i]) && !empty(studentReport[i].comment)
            ? studentReport[i].comment
            : "";
        break;
      }
    }

    setStudentReportComment(comment);
    setStudentAssessment(currentStudentAssessmentData);
    setActiveDetails(data);
  };

  //function to hide school edit modal
  const closeSchoolEidtDialog = () => {
    setIsStudentDialog(false);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setFirst(0);
  };

  const subjectTemplate = (data) => {
    const assessmentSubjectId =
      !empty(data) && !empty(data.subjectId) ? data.subjectId : "";
    const subjectTitle =
      !empty(reIndexedSubjects) &&
      !empty(reIndexedSubjects[assessmentSubjectId]) &&
      !empty(reIndexedSubjects[assessmentSubjectId].title)
        ? reIndexedSubjects[assessmentSubjectId].title
        : "";

    return subjectTitle;
  };

  const ca1Template = (data) => {
    const ca1 = !empty(data) && !empty(data.ca1) ? data.ca1 : 0;
    return ca1;
  };

  const ca2Template = (data) => {
    const ca2 = !empty(data) && !empty(data.ca2) ? data.ca2 : 0;
    return ca2;
  };

  const examTemplate = (data) => {
    const exam = !empty(data) && !empty(data.exam) ? data.exam : 0;
    return exam;
  };

  let assessmentSum = 0;
  let assessmentCount = 0;
  const totalTemplate = (ca1, ca2, examScore) => {
    const total = parseInt(ca1) + parseInt(ca2) + parseInt(examScore);
    assessmentCount++;
    assessmentSum += total;
    if (assessmentCount + 1 === studentAssessment.length) {
      setSelectedStudentTotal(assessmentSum);
    }

    let score_tag = "";
    if (total >= 70) {
      score_tag = "green";
    } else if (total >= 60 && total <= 69.9) {
      score_tag = "#633ccd";
    } else if (total >= 50 && total <= 59.9) {
      score_tag = "orange";
    } else if (total >= 45 && total <= 49.9) {
      score_tag = "gray";
    } else {
      score_tag = "red";
    }

    return <span style={{ color: score_tag }}>{total}</span>;
  };

  const gradeTemplate = (ca1, ca2, exam) => {
    const total = parseInt(ca1) + parseInt(ca2) + parseInt(exam);

    const getGrade = findGrade(
      principalComments,
      headMasterMistressComments,
      classDataCategory,
      classId,
      total
    );
    const grade =
      !empty(getGrade) && !empty(getGrade.grade) ? getGrade.grade : "";

    return grade;
  };

  const remarkTemplate = (ca1, ca2, exam) => {
    const total = parseInt(ca1) + parseInt(ca2) + parseInt(exam);

    const getGrade = findGrade(
      principalComments,
      headMasterMistressComments,
      classDataCategory,
      classId,
      total
    );
    const remark =
      !empty(getGrade) && !empty(getGrade.remark) ? getGrade.remark : "";

    return remark;
  };

  const classDataCategory =
    !empty(classData) && !empty(classData.category) ? classData.category : "";

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              title="Record Assessments"
              children={
                <>
                  <span style={{ marginRight: 30 }}>
                    Session: <strong>{currentSessionTitle}</strong>
                  </span>

                  <span style={{ marginRight: 30 }}>
                    Term: <strong>{currentTermTitle}</strong>
                  </span>

                  <span style={{ marginRight: 30 }}>
                    Class:{" "}
                    <strong>
                      {!empty(classData) && !empty(classData.title)
                        ? classData.title
                        : ""}
                    </strong>
                  </span>

                  <span>
                    Subject:{" "}
                    <strong>
                      {!empty(subjectData) && !empty(subjectData.title)
                        ? subjectData.title
                        : ""}
                    </strong>
                  </span>
                </>
              }
            />
            {/* end of header */}

            {/* table start */}
            <StudentsTable
              students={studentData}
              openAssessmentModal={openAssessmentModal}
              onPageChange={onPageChange}
              loading={isLoading}
              rows={rows}
              totalRecords={totalRecords}
              first={first}
              search={search}
              onSearchChange={onSearchChange}
              assessment={true}
              recordScores={true}
            />
            {/* table end  */}
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
      </AppWrapper>

      <Dialog
        visible={isStudentDialog}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={`Record Assessment`}
        modal
        className="p-fluid assessment_modal"
        onHide={closeSchoolEidtDialog}
      >
        <>
          <p className="assessment_top_details">
            <span>
              <strong>Student Name:</strong>

              {!empty(activeDetails) && !empty(activeDetails.fullName)
                ? " " + activeDetails.fullName
                : ""}
            </span>
            <span>
              <strong>Subject:</strong>
              {!empty(subjectData) && !empty(subjectData.title)
                ? " " + subjectData.title
                : ""}
            </span>
            <span>
              <strong>Class:</strong>
              {!empty(classData) && !empty(classData.title)
                ? " " + classData.title
                : ""}
            </span>
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form
              style={{
                width: "100%",
                marginTop: 20,
              }}
            >
              <div className="app_input_group">
                <InputField
                  placeholder="Enter CA1 Score"
                  name="ca1"
                  height={50}
                  type="number"
                  labelTitle="CA1"
                />
                <InputField
                  placeholder="Enter CA2 Score"
                  name="ca2"
                  height={50}
                  type="number"
                  labelTitle="CA2"
                />
                <InputField
                  placeholder="Enter Exam Score"
                  name="exam"
                  height={50}
                  type="number"
                  labelTitle="Exam"
                />
              </div>
              <ButtonIcon
                height={45}
                marginTop={2}
                color="#ffffff"
                backgroundColor="#633ccd"
                width={120}
                borderColor="#633ccd"
                buttonText="Update"
                type="submit"
              />
            </Form>
          </Formik>
          <div>
            <div style={{ marginTop: 50, marginBottom: 20 }}>
              <strong style={{ fontSize: 20 }}>Recorded Assessments</strong>
              <br />
              <strong>
                {currentSessionTitle} - {currentTermTitle}
              </strong>
              <p style={{ marginTop: 10, fontSize: 14, color: "red" }}>
                <em>
                  <strong>Note: </strong>For selected <strong>Session</strong>{" "}
                  and <strong>Term</strong>
                </em>
              </p>
            </div>
            {
              <DataTable
                value={studentAssessment}
                tableStyle={{ minWidth: "30rem" }}
                dataKey="_id"
              >
                <Column
                  field="subjects"
                  header="Subjects"
                  body={subjectTemplate}
                ></Column>
                <Column field="ca1" header="CA1" body={ca1Template}></Column>
                <Column field="ca2" header="CA2" body={ca2Template}></Column>
                <Column field="exam" header="Exam" body={examTemplate}></Column>
                <Column
                  field="total"
                  header="Total"
                  body={(rowData) => {
                    const examScore = examTemplate(rowData);
                    const ca1 = ca1Template(rowData);
                    const ca2 = ca2Template(rowData);
                    return totalTemplate(ca1, ca2, examScore);
                  }}
                ></Column>
                <Column
                  field="grade"
                  header="Grade"
                  body={(rowData) => {
                    const examScore = examTemplate(rowData);
                    const ca1 = ca1Template(rowData);
                    const ca2 = ca2Template(rowData);
                    return gradeTemplate(ca1, ca2, examScore);
                  }}
                ></Column>
                <Column
                  field="remark"
                  header="Remark"
                  body={(rowData) => {
                    const examScore = examTemplate(rowData);
                    const ca1 = ca1Template(rowData);
                    const ca2 = ca2Template(rowData);
                    return remarkTemplate(ca1, ca2, examScore);
                  }}
                ></Column>
              </DataTable>
            }
            <div className="mt-20 flex assessment_summary">
              <span className="mr-60">
                <strong>No. of Subjects: </strong>
                {studentAssessment.length}
              </span>
              <span className="mr-60">
                <strong>Total: </strong>
                {selectedStudentTotal}
              </span>
              <span>
                <strong>Average: </strong>
                {typeof selectedStudentTotal === NaN ||
                typeof studentAssessment.length === NaN ||
                studentAssessment.length <= 0
                  ? 0
                  : (selectedStudentTotal / studentAssessment.length).toFixed(
                      1
                    )}
                %
              </span>
            </div>
          </div>
          <div style={{ marginTop: 40, marginBottom: 10 }}>
            <strong style={{ fontSize: 18 }}>Class Teacher's Comment</strong>
            <br />
            <strong>
              {currentSessionTitle} - {currentTermTitle}
            </strong>
            <p style={{ marginTop: 10, fontSize: 14, color: "red" }}>
              <em>
                <strong>Note: </strong>Enter comment for student's overall
                performance
              </em>
            </p>
          </div>
          <Formik
            initialValues={{ comment: studentReportComment }}
            validationSchema={validationCommentSchema}
            onSubmit={handleCommentSubmit}
          >
            <Form
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 8,
                }}
              >
                <InputField
                  placeholder="Enter comment for student performance"
                  name="comment"
                  height={90}
                  labelTitle="Comment"
                  as="textarea"
                  rows={8}
                  cols={10}
                />
              </div>
              <ButtonIcon
                height={45}
                marginTop={2}
                color="#ffffff"
                backgroundColor="#633ccd"
                width={120}
                borderColor="#633ccd"
                buttonText="Update"
                type="submit"
              />
            </Form>
          </Formik>
        </>
      </Dialog>

      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default RecordAssessment;
