import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";

function ErrorBoundary() {
  let error = useRouteError();
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
}

export default ErrorBoundary;
