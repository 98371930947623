import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import classApi from "../../api/Classes";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Tag } from "primereact/tag";

const SubjectsToAssess = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const staffClasses = !empty(user) && !empty(user.classes) ? user.classes : [];
  const currentTermTitle =
    !empty(user) && !empty(user.currentTermTitle) ? user.currentTermTitle : "";
  const currentSessionTitle =
    !empty(user) && !empty(user.currentSessionTitle)
      ? user.currentSessionTitle
      : "";
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [subjectData, setSubjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [classSubjects, setClassSubjects] = useState([]);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
  const [subjectIds, setSubjectIds] = useState([]);
  const [classTitle, setClassTitle] = useState("");
  const [checked, setChecked] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const paramState = !empty(location.state) ? location.state : {};
  const classId =
    !empty(paramState) && !empty(paramState.classId) ? paramState.classId : "";
  const sessionId =
    !empty(paramState) && !empty(paramState.sessionId)
      ? paramState.sessionId
      : "";
  const termId =
    !empty(paramState) && !empty(paramState.termId) ? paramState.termId : "";

  useEffect(() => {
    // fetch subject
    if (
      empty(classId) ||
      empty(termId) ||
      empty(sessionId) ||
      !staffClasses.includes(classId)
    ) {
      return navigate("/404");
    }
    getClassDetails();
  }, []);

  useEffect(() => {
    if (empty(selectedSubjectIds)) {
      setShowAddButton(false);
    } else {
      setShowAddButton(true);
    }
  }, [selectedSubjectIds]);

  const getSubjects = async (subjectsInClass) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSubjectsInClass(
        classId,
        schoolId,
        10000,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        setSubjectData([]);
      } else {
        const results =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        let updatedSubjectids = [];
        let updatedResults = [];
        for (let i = 0; i < results.length; i++) {
          const subjectId =
            !empty(results[i]) && !empty(results[i]._id) ? results[i]._id : "";
          updatedSubjectids.push(subjectId);
          results[i].added = subjectsInClass.includes(subjectId);
          updatedResults.push(results[i]);
        }
        setSubjectIds(updatedSubjectids);
        setSubjectData(updatedResults);
      }

      if (location.state) {
        const { subjectAdded = false, subjectUpdated = false } = location.state;
        if (subjectAdded || subjectUpdated) {
          const actionType = subjectAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Subject ${actionType} successfully!`
          );
          navigate(location.pathname, {
            replace: true,
            state: { subjectAdded: false, subjectUpdated: false },
          });
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    } finally {
      setIsLoading(false);
    }
  };

  const getClassDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSingleClass(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to fetch class details"
        );
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        const classTitle = !empty(data.title) ? data.title : "";
        const reports = !empty(data.reports) ? data.reports : [];
        let currentReportSubjects = [];
        if (!empty(reports)) {
          for (let i = 0; i < reports.length; i++) {
            const itemTermId =
              !empty(reports[i]) && !empty(reports[i].termId)
                ? reports[i].termId
                : "";
            const itemSessionId =
              !empty(reports[i]) && !empty(reports[i].sessionId)
                ? reports[i].sessionId
                : "";
            if (itemTermId === termId && itemSessionId === sessionId) {
              const _reports = !empty(reports[i]) ? reports[i] : [];
              currentReportSubjects = !empty(_reports)
                ? _reports.subjectsToAssess
                : [];
              break;
            }
          }
        }
        const subjectsInClass = !empty(data.subjects) ? data.subjects : [];
        if (currentReportSubjects.length === subjectsInClass.length) {
          setChecked(true);
        }
        setSelectedSubjectIds(currentReportSubjects);
        setClassSubjects(subjectsInClass);
        setClassTitle(classTitle);

        getSubjects(subjectsInClass);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    }
  };

  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const updateSelectedSubjectIds = (subjectId) => {
    let updatedSubjects = [];
    const index = selectedSubjectIds.indexOf(subjectId);
    if (index > -1) {
      updatedSubjects = selectedSubjectIds.filter((id) => id !== subjectId);
    } else {
      updatedSubjects = [...selectedSubjectIds, subjectId];
    }
    setSelectedSubjectIds(updatedSubjects);
    if (updatedSubjects.length !== subjectIds.length) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const positionClass = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      let _dataToUpdate = [];
      if (selectedSubjectIds.every((item) => classSubjects.includes(item))) {
        _dataToUpdate = selectedSubjectIds;
      } else {
        _dataToUpdate = [...new Set([...classSubjects, ...selectedSubjectIds])];
      }

      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.postionClass(
        schoolId,
        classId,
        sessionId,
        termId,
        _dataToUpdate,
        token
      );
      const response_data = prepareResponseData(response);
      setSelectedSubjectIds([]);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Something went wrong",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Operation failed"
        );
      } else {
        responseDailog("success", "Success", "Class positioned successfully");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      getClassDetails();
      setIsLoading(false);
    }
  };

  const checkBoxBodyTemplate = (rowData) => {
    const subjectId = !empty(rowData) && !empty(rowData._id) ? rowData._id : "";
    return (
      <Checkbox
        checked={selectedSubjectIds.includes(subjectId)}
        onChange={() => updateSelectedSubjectIds(subjectId)}
      />
    );
  };

  const statusTemplateBody = (rowData) => {
    const subjectId = !empty(rowData) && !empty(rowData._id) ? rowData._id : "";
    const included = selectedSubjectIds.includes(subjectId);
    return (
      <Tag
        value={included ? <FaCheck /> : <FaTimes />}
        severity={included ? "success" : "danger"}
      ></Tag>
    );
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            <MainHeader
              children={
                showAddButton ? (
                  <>
                    <ButtonIcon
                      borderColor="#633ccd"
                      backgroundColor="transparent"
                      color="#633ccd"
                      buttonText="Position Class"
                      marginRight={1}
                      width={170}
                      height={30}
                      onClick={() => positionClass()}
                    />
                  </>
                ) : (
                  ""
                )
              }
              redirect={false}
              title={`Add/Remove subject(s) to Assess in ${classTitle}, for ${currentSessionTitle} - ${currentTermTitle}`}
            />
            <div style={{ marginBottom: 10 }}>
              <span style={{ color: "red", fontStyle: "italic" }}>
                Add subjects that you intend the class positioning to be
                performed with.
              </span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <span style={{ marginRight: 40 }}>
                <strong>No. of Subjects In class: </strong>
                {classSubjects.length}
              </span>
              <span>
                <strong>No. of Selected Subjects: </strong>
                {selectedSubjectIds.length}
              </span>
            </div>
            <DataTable value={subjectData} tableStyle={{ minWidth: "30rem" }}>
              <Column
                field="_id"
                header={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.checked);
                      if (e.checked) {
                        setShowAddButton(true);
                        setSelectedSubjectIds(subjectIds);
                      } else {
                        setSelectedSubjectIds([]);
                      }
                    }}
                  />
                }
                body={checkBoxBodyTemplate}
              />
              <Column field="title" header="Title" />
              <Column field="code" header="Code" />
              <Column
                field="status"
                header="Status"
                body={statusTemplateBody}
              />
            </DataTable>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
      </AppWrapper>

      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default SubjectsToAssess;
