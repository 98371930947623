import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData, reIndex } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import _ from "lodash";
import jsPDF from "jspdf";
import { API_BASE_URL } from "../../config/config";

// css
import "../students/Students.css";
import "./Assessment.css";

// api
import studentApi from "../../api/Student";
import classApi from "../../api/Classes";
import termApi from "../../api/Terms";
import sessionApi from "../../api/Session";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import StudentsTable from "../../components/tables/primeTable/students/StudentsTable";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { Button } from "primereact/button";

const ReportSheet = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const reportTemplateRef = useRef(null);
  const paramState = !empty(location.state) ? location.state : {};
  const classId =
    !empty(paramState) && !empty(paramState.classId) ? paramState.classId : "";
  const sessionId =
    !empty(paramState) && !empty(paramState.sessionId)
      ? paramState.sessionId
      : "";
  const termId =
    !empty(paramState) && !empty(paramState.termId) ? paramState.termId : "";
  //ref
  const toastTR = useRef(null);
  // states
  const [studentData, setStudentData] = useState([]);
  const [subjectData, setSubjectData] = useState({});
  const [classData, setClassData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [search, setSearch] = useState("");
  const [studentAssessment, setStudentAssessment] = useState([]);
  const [reIndexedSubjects, setReIndexedSubjects] = useState({});
  const [activeDetails, setActiveDetails] = useState({});
  const [resultSheet, setResultSheet] = useState(false);
  const [termData, setTermData] = useState({});
  const [sessionData, setSessionData] = useState({});
  const [studentReport, setStudentReport] = useState({});

  const schoolName =
    !empty(user) && !empty(user.schoolName) ? user.schoolName : "";
  const schoolAvatar =
    !empty(user) && !empty(user.schoolAvatar) ? user.schoolAvatar : "";
  const contact1 =
    !empty(user) && !empty(user.schoolContact) ? user.schoolContact : "";
  const email =
    !empty(user) && !empty(user.schoolEmail) ? user.schoolEmail : "";
  const address = !empty(user) && !empty(user.address) ? user.address : "";
  const closingDate =
    !empty(user) && !empty(user.closingDate) ? user.closingDate : "";
  const openingDate =
    !empty(user) && !empty(user.openingDate) ? user.openingDate : "";

  useEffect(() => {
    // fetch student
    try {
      if (!empty(classId) && !empty(sessionId) && !empty(termId)) {
        getStudents();
        getSubjectsInClass();
        getClassDetails();
        getTermDetails();
        getSessionDetails();
      } else {
        navigate("/404");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load student. Please try again later."
      );
    }
  }, [first, rows, search, classId]);

  // function to get all student
  const getStudents = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const page = first / rows;
      let response;
      response = await studentApi.getStudentsInClass(
        schoolId,
        page,
        rows,
        search,
        classId,
        "assessment",
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudentData([]);
      } else {
        setStudentData(response_data.response);
        setTotalRecords(!empty(response_data.count) ? response_data.count : 0);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const getClassDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSingleClass(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setClassData({});
      } else {
        setClassData(response_data.response);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    }
  };

  const getTermDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await termApi.getSingleTerm(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setTermData({});
      } else {
        setTermData(response_data.response);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    }
  };

  const getSubjectsInClass = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSubjectsInClass(
        classId,
        schoolId,
        1000,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setReIndexedSubjects({});
      } else {
        const classSubjects = response_data.response;
        const reIndexedSubjects = reIndex(classSubjects, "_id");
        setReIndexedSubjects(reIndexedSubjects);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    }
  };

  const getSessionDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await sessionApi.getSession(sessionId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setSessionData({});
      } else {
        setSessionData(response_data.response);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const assessmentData = (data) => {
    const studentAssessmentData =
      !empty(data) && !empty(data.assessment) ? data.assessment : [];
    const studentReport =
      !empty(data) && !empty(data.reports) ? data.reports : [];
    const classReports =
      !empty(classData) && !empty(classData.reports) ? classData.reports : [];
    let classReport = {};
    for (let i = 0; i < classReports.length; i++) {
      const data = !empty(classReports[i]) ? classReports[i] : {};
      const reportSessionId = !empty(data.sessionId) ? data.sessionId : "";
      const reportTermId = !empty(data.termId) ? data.termId : "";
      if (reportSessionId === sessionId && reportTermId === termId) {
        classReport = data;
        break;
      }
    }
    const selectedSubjectIds =
      !empty(classReport) && !empty(classReport.subjectsToAssess)
        ? classReport.subjectsToAssess
        : [];
    const currentStudentAssessmentData = studentAssessmentData.filter(
      (item) => {
        const itemSessionId =
          !empty(item) && !empty(item.sessionId) ? item.sessionId : "";
        const itemTermId =
          !empty(item) && !empty(item.termId) ? item.termId : "";
        const itemSubjectId =
          !empty(item) && !empty(item.subjectId) ? item.subjectId : "";
        if (
          itemSessionId === sessionId &&
          itemTermId === termId &&
          selectedSubjectIds.includes(itemSubjectId)
        ) {
          const subjectId =
            !empty(item) && !empty(item.subjectId) ? item.subjectId : "";
          return (item.subjectTitle =
            !empty(reIndexedSubjects) &&
            !empty(reIndexedSubjects[subjectId]) &&
            !empty(reIndexedSubjects[subjectId].title)
              ? reIndexedSubjects[subjectId].title
              : "");
        }
      }
    );
    const currentReport = studentReport.find((item) => {
      const itemSessionId =
        !empty(item) && !empty(item.sessionId) ? item.sessionId : "";
      const itemTermId = !empty(item) && !empty(item.termId) ? item.termId : "";
      return itemSessionId === sessionId && itemTermId === termId;
    });
    setStudentReport(currentReport);
    setStudentAssessment(currentStudentAssessmentData);
    setActiveDetails(data);
  };

  // function to show result sheet
  const getStudentReportSheet = (data) => {
    assessmentData(data, "report");
    setResultSheet(true);
  };

  // function to hide result sheet
  const hideReportSheetDialog = () => {
    setResultSheet(false);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setFirst(0);
  };

  const handleGeneratePdf = async () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save(
          _.toUpper(activeDetails.firstName + "_" + activeDetails.regNo)
        );
      },
    });
  };

  const assessmentDialogFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="Download"
        icon="pi pi-download"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={handleGeneratePdf}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              title="Students"
              children={
                <>
                  <span style={{ marginRight: 30 }}>
                    Class:{" "}
                    <strong>
                      {!empty(classData) && !empty(classData.title)
                        ? classData.title
                        : ""}
                    </strong>
                  </span>

                  <span>
                    Subject:{" "}
                    <strong>
                      {!empty(subjectData) && !empty(subjectData.title)
                        ? subjectData.title
                        : ""}
                    </strong>
                  </span>
                </>
              }
            />
            {/* end of header */}

            {/* table start */}
            <StudentsTable
              students={studentData}
              getStudentReportScreen={getStudentReportSheet}
              onPageChange={onPageChange}
              loading={isLoading}
              rows={rows}
              totalRecords={totalRecords}
              first={first}
              search={search}
              onSearchChange={onSearchChange}
              assessment={true}
              reportSheet={true}
            />
            {/* table end  */}
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
      </AppWrapper>

      {/* 
        report modal
      */}
      <Dialog
        visible={resultSheet}
        className="result_sheet_modal"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Report Card"
        modal
        footer={assessmentDialogFooter}
        onHide={hideReportSheetDialog}
      >
        <div
          className="confirmation-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="report-body" ref={reportTemplateRef}>
            {/* report header */}
            <div className="report-header">
              <div style={{ width: 50, height: 50 }}>
                <img
                  src={!empty(schoolAvatar) ? API_BASE_URL + schoolAvatar : ""}
                  alt="non"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <p className="report-title">
                  {!empty(schoolName) ? schoolName : "-"}
                </p>
                <p className="report-subtitle">
                  {!empty(address) ? address : "-"}
                </p>
                <div className="report_details">
                  <p className="report-subtitle">{`TEL.: ${contact1}`}</p>
                  <p className="report-subtitle">{`Email: ${email}`}</p>
                </div>
              </div>
              <div style={{ width: 50, height: 50 }}>
                <img
                  src={!empty(schoolAvatar) ? API_BASE_URL + schoolAvatar : ""}
                  alt="non"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
            {/* end of report header */}

            {/* report top section */}
            <div className="report-details-header">
              <p className="report-term-title">
                {!empty(termData) && !empty(termData.title)
                  ? termData.title
                  : ""}{" "}
                TERM STUDENT'S PERFORMANCE SHEET
              </p>
              <div className="report-detail-group">
                <p className="report-detail-group-item">
                  <span className="report-detail-group-title">NAME:</span>
                  <span className="report-detail-group-value">
                    {!empty(activeDetails) && !empty(activeDetails.fullName)
                      ? _.toUpper(activeDetails.fullName)
                      : ""}
                  </span>
                </p>
                <p className="report-detail-group-item">
                  <span className="report-detail-group-title">GENDER:</span>
                  <span className="report-detail-group-value">
                    {!empty(activeDetails) && !empty(activeDetails.gender)
                      ? _.toUpper(activeDetails.gender)
                      : ""}
                  </span>
                </p>
              </div>
              <div className="report-detail-group">
                <p className="report-detail-group-item">
                  <span className="report-detail-group-title">CLASS:</span>
                  <span className="report-detail-group-value">
                    {!empty(activeDetails) && !empty(activeDetails.class)
                      ? _.toUpper(activeDetails.class)
                      : ""}
                  </span>
                </p>
                <p className="report-detail-group-item">
                  <span className="report-detail-group-title">SESSION:</span>
                  <span className="report-detail-group-value">
                    {!empty(sessionData) && !empty(sessionData.title)
                      ? sessionData.title
                      : ""}
                  </span>
                </p>
                <p className="report-detail-group-item">
                  <span className="report-detail-group-title">
                    ADMISSION NO.:
                  </span>
                  <span className="report-detail-group-value">
                    {!empty(activeDetails) && !empty(activeDetails.regNo)
                      ? _.toUpper(activeDetails.regNo)
                      : ""}
                  </span>
                </p>
              </div>

              <div className="report-rating-box">
                <table className="grade-rating-table ">
                  <thead>
                    <tr>
                      <th>GRADE</th>
                      <th>A</th>
                      <th>B</th>
                      <th>C</th>
                      <th>D</th>
                      <th>E</th>
                      <th>F</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>NO</th>
                      <td>
                        {!empty(studentReport) && !empty(studentReport.noOfAs)
                          ? _.toUpper(studentReport.noOfAs)
                          : 0}
                      </td>
                      <td>
                        {!empty(studentReport) && !empty(studentReport.noOfBs)
                          ? _.toUpper(studentReport.noOfBs)
                          : 0}
                      </td>
                      <td>
                        {!empty(studentReport) && !empty(studentReport.noOfCs)
                          ? _.toUpper(studentReport.noOfCs)
                          : 0}
                      </td>
                      <td>
                        {!empty(studentReport) && !empty(studentReport.noOfDs)
                          ? _.toUpper(studentReport.noOfDs)
                          : 0}
                      </td>
                      <td>
                        {!empty(studentReport) && !empty(studentReport.noOfEs)
                          ? _.toUpper(studentReport.noOfEs)
                          : 0}
                      </td>
                      <td>
                        {!empty(studentReport) && !empty(studentReport.noOfFs)
                          ? _.toUpper(studentReport.noOfFs)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={6}>SUBJECTS OFFERED</th>
                      <td>
                        {!empty(studentReport) &&
                        !empty(studentReport.noOfSubjectsOffered)
                          ? studentReport.noOfSubjectsOffered
                          : "NA"}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="score-rating-table">
                  <tbody>
                    <tr>
                      <th>AVERAGE</th>
                      <td>
                        {!empty(studentReport) && !empty(studentReport.average)
                          ? studentReport.average
                          : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>GRADE</th>
                      <td>
                        {!empty(studentReport) &&
                        !empty(studentReport.grade) &&
                        !empty(studentReport.remark)
                          ? studentReport.grade +
                            " - " +
                            _.toUpper(studentReport.remark)
                          : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>POSITION</th>
                      <td>
                        {!empty(studentReport) && !empty(studentReport.position)
                          ? _.toUpper(studentReport.position)
                          : "NA"}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="score-rating-table">
                  <tbody>
                    <tr>
                      <td>Marks Obtained</td>
                      <td>
                        {!empty(studentReport) &&
                        !empty(studentReport.cumulativeScore)
                          ? studentReport.cumulativeScore
                          : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <td>Marks Obtainable</td>
                      <td>
                        {!empty(studentReport) &&
                        !empty(studentReport.noOfSubjectsOffered)
                          ? studentReport.noOfSubjectsOffered * 100
                          : "NA"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* end of report top secit */}

            <div className="report-containter">
              <table className="report-sheet-area">
                <thead>
                  <tr>
                    <th rowSpan={3}>COGNITIVE DOMAIN</th>
                    <th rowSpan={2} colSpan={3}>
                      CA
                    </th>
                    <th rowSpan={3}>EXAM</th>
                    <th rowSpan={3}>TOTAL</th>
                    <th rowSpan={4}>GRADE</th>
                    {/* <th rowSpan={4}>
                      <span className="report-title-rotate">POSITION</span>
                    </th> */}
                    <th rowSpan={4}>REMARKS</th>
                    {/* <th rowSpan={4}>
                      <span className="report-title-rotate">CLASS MIN.</span>
                    </th>
                    <th rowSpan={4}>
                      <span className="report-title-rotate">CLASS MAX.</span>
                    </th>
                    <th rowSpan={4}>
                      <span className="report-title-rotate">CLASS AVG.</span>
                    </th> */}
                  </tr>
                  <tr></tr>
                  <tr>
                    <th>1st</th>
                    <th>2nd</th>
                    <th>3rd</th>
                  </tr>
                  <tr>
                    <th>SUBJECTS</th>
                    <th>20</th>
                    <th>20</th>
                    <th>-</th>
                    <th>60</th>
                    <th>100</th>
                  </tr>
                </thead>
                <tbody>
                  {!empty(studentAssessment) ? (
                    studentAssessment.map((data) => {
                      return (
                        <tr
                          key={
                            !empty(data) && !empty(data.subjectId)
                              ? data.subjectId
                              : ""
                          }
                        >
                          <td>
                            {!empty(data) && !empty(data.subjectTitle)
                              ? _.toUpper(data.subjectTitle)
                              : ""}
                          </td>
                          <td>
                            {!empty(data) && !empty(data.ca1) ? data.ca1 : 0}
                          </td>
                          <td>
                            {!empty(data) && !empty(data.ca2) ? data.ca2 : 0}
                          </td>
                          <td>-</td>
                          <td>
                            {!empty(data) && !empty(data.exam) ? data.exam : 0}
                          </td>
                          <td>
                            {!empty(data) && !empty(data.total)
                              ? data.total
                              : 0}
                          </td>
                          <td>
                            {!empty(data) && !empty(data.grade)
                              ? data.grade
                              : ""}
                          </td>
                          {/* <td>
                              {!_.isEmpty(data.position) ? data.position : "NA"}
                            </td> */}
                          <td>
                            {!empty(data) && !empty(data.remark)
                              ? _.toUpper(data.remark)
                              : ""}
                          </td>
                          {/* <td>-</td>
                            <td>-</td>
                            <td>-</td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="report-summary-sheet">
              <table className="report-summary-table">
                <tbody>
                  <tr>
                    <th>Remarks from teacher</th>
                    <td colSpan={3}>
                      {!empty(studentReport) && !empty(studentReport.comment)
                        ? studentReport.comment
                        : !empty(studentReport) &&
                          !empty(studentReport.teachersComment)
                        ? studentReport.teachersComment
                        : "NA"}
                    </td>
                  </tr>
                  <tr>
                    <th>Teacher's Name</th>
                    <td colSpan={3}>
                      {!empty(classData) && !empty(classData.classTeacher)
                        ? classData.classTeacher
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th>Principal's Remark</th>
                    <td colSpan={3}>
                      {!empty(studentReport) &&
                      !empty(studentReport.principalComments)
                        ? studentReport.principalComments
                        : "NA"}
                    </td>
                  </tr>
                  <tr>
                    <th>Director's Name</th>
                    <td>Mr. Ukoha Orji Okoro</td>
                    <td colSpan={2}>
                      Sign: <em>Management</em>
                    </td>
                  </tr>
                  <tr>
                    <th>Next Term Begins</th>
                    <td>{!empty(openingDate) ? openingDate : "NA"}</td>
                    <td>Date: </td>
                    <td>{!empty(closingDate) ? closingDate : "NA"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default ReportSheet;
