import _ from "lodash";

const prepareResponseData = (response) => {
  if (
    empty(response) ||
    empty(response.data) ||
    empty(response.data.success) ||
    empty(response.statusText) ||
    response.statusText !== "OK"
  ) {
    return !empty(response.data) && !empty(response.data.message)
      ? {
          ...(isObject(response.data.message)
            ? response.data.message
            : { response: response.data.message }),
          success: false,
          logOut:
            !empty(response.data) && !empty(response.data.message.logOut)
              ? response.data.message.logOut
              : false,
          statusCodeType:
            !empty(response.data) && !empty(response.data.statusCodeType)
              ? response.data.statusCodeType
              : false,
        }
      : {
          response: "",
          success: true,
          statusCodeType:
            !empty(response.data) && !empty(response.data.statusCodeType)
              ? response.data.statusCodeType
              : false,
        };
  }
  const response_data =
    !empty(response) &&
    !empty(response.data) &&
    !empty(response.data.message) &&
    !empty(response.data.success)
      ? {
          response: response.data.message,
          success: response.data.success,
          count: !empty(response.data.count) ? response.data.count : 0,
        }
      : {};
  return response_data;
};

const isNumber = (value = null) => {
  try {
    return (
      typeof value === "number" &&
      value === value &&
      value !== Infinity &&
      value !== -Infinity
    );
  } catch (error) {
    return false;
  }
};

const isBoolean = (value = null) => {
  return typeof value === "boolean" || value === true || value === false;
};

const isUndefined = (value = null) => {
  return typeof value === "undefined" || value === undefined;
};

const isObject = (value = null) => {
  return typeof value === "object" &&
    Object.prototype.toString.call(value) === "[object Object]"
    ? true
    : false;
};

const isArray = (value = null) => {
  return (typeof value === "object" &&
    Object.prototype.toString.call(value) === "[object Array]") ||
    Array.isArray(value)
    ? true
    : false;
};

const isString = (value = null) => {
  return typeof value === "string";
};

const isNull = (value = null) => {
  return value === null ? true : false;
};

const empty = (value = null) => {
  let flag = false;
  if (isString(value) && (value === "" || value.trim() === "")) flag = true;
  else if (isNumber(value) && value === 0) flag = true;
  else if (isBoolean(value) && value === false) flag = true;
  else if (isObject(value) && Object.values(value).length === 0) flag = true;
  else if (isArray(value) && value.length === 0) flag = true;
  else if (isUndefined(value)) flag = true;
  else if (isNull(value)) flag = true;
  else if (typeof value === "array" && value.length === 0) flag = true;
  else if (typeof value === "object" && Object.keys(value).length === 0)
    flag = true;

  return flag;
};

const reIndex = (array, key = "_id") => {
  const indexed_array = {};
  if ((_.isArray(array) || _.isObject(array)) && !_.isEmpty(array)) {
    _.forEach(array, (item) => {
      if (_.isObject(item) && _.has(item, key)) {
        indexed_array[item[key]] = item;
      }
    });
    return indexed_array;
  } else {
    return {};
  }
};

const findGrade = (principalRemarks, headRemarks, category, classId, score) => {
  const arrayToIterate =
    category === "SECONDARY" ? principalRemarks : headRemarks;

  for (const item of arrayToIterate) {
    if (
      item.min <= score &&
      score <= item.max &&
      item.classes.includes(classId)
    ) {
      return item;
    }
  }

  return {};
};

export {
  prepareResponseData,
  empty,
  isNumber,
  isArray,
  isBoolean,
  isObject,
  isString,
  isUndefined,
  isNull,
  reIndex,
  findGrade,
};
