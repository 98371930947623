import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "../staff/Staff.css";

// api
import studentApi from "../../api/Student";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import StatCard from "../../components/statcard/StatCard";
import {
  FaBookReader,
  FaChartArea,
  FaChartLine,
  FaStar,
  FaUserGraduate,
} from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import Underline from "../../components/others/Underline";
import { colors } from "../../Utilities/colors";

const StudentsProfile = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const [studentData, setStudentData] = useState({});
  const [classTitle, setClassTitle] = useState(0);
  const [numOfSubjects, setNumOfSubjects] = useState(0);
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [assessmentPercentage, setAssessmentPercentage] = useState(0);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const { studentId } = useParams();
  const toastTR = useRef(null);

  if (!studentId) {
    navigate("/404");
  }

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    getStudentPercentage();
    getStudentDetails();
  }, [studentId]);

  const getStudentDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await studentApi.getStudentProfile(
        studentId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      const studentDetails =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const title =
        !empty(response_data) && !empty(studentDetails.classTitle)
          ? studentDetails.classTitle
          : [];
      const subjects =
        !empty(studentDetails) && !empty(studentDetails.assignedSubjects)
          ? studentDetails.assignedSubjects
          : [];
      setAssignedSubjects(subjects);
      setClassTitle(title);
      setNumOfSubjects(subjects.length);
      setStudentData(studentDetails);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getStudentPercentage = async () => {
    setIsLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await studentApi.getStudentPercentage(
        studentId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong!"
        );
      }
      setAssessmentPercentage(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const firstName =
    !empty(studentData) && !empty(studentData.firstName)
      ? studentData.firstName
      : "";
  const lastName =
    !empty(studentData) && !empty(studentData.lastName)
      ? studentData.lastName
      : "";
  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);
  const middleName =
    !empty(studentData) && !empty(studentData.middleName)
      ? studentData.middleName
      : "";
  const gender =
    !empty(studentData) && !empty(studentData.gender) ? studentData.gender : "";
  const title =
    !empty(studentData) && !empty(studentData.title) ? studentData.title : "";
  const address =
    !empty(studentData) && !empty(studentData.address)
      ? studentData.address
      : "";
  const stateOfResidence =
    !empty(studentData) && !empty(studentData.stateOfResidence)
      ? studentData.stateOfResidence
      : "";
  const lgaOfResidence =
    !empty(studentData) && !empty(studentData.lgaOfResidence)
      ? studentData.lgaOfResidence
      : "";
  const stateOfOrigin =
    !empty(studentData) && !empty(studentData.stateOfOrigin)
      ? studentData.stateOfOrigin
      : "";
  const lgaOfOrigin =
    !empty(studentData) && !empty(studentData.lgaOfOrigin)
      ? studentData.lgaOfOrigin
      : "";
  const nationality =
    !empty(studentData) && !empty(studentData.nationality)
      ? studentData.nationality
      : "";
  const religion =
    !empty(studentData) && !empty(studentData.religion)
      ? studentData.religion
      : "";
  const dateOfBirth =
    !empty(studentData) && !empty(studentData.dateOfBirth)
      ? studentData.dateOfBirth
      : "";
  const active =
    !empty(studentData) && !empty(studentData.active) ? studentData.active : "";
  const phoneNumber =
    !empty(studentData) && !empty(studentData.phoneNumber)
      ? studentData.phoneNumber
      : "";
  const email =
    !empty(studentData) && !empty(studentData.email) ? studentData.email : "";
  const previousSchool =
    !empty(studentData) && !empty(studentData.previous_school)
      ? studentData.previous_school
      : "";

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Student Profile" />
          {/* end menu header */}
          <div className="mt-10 dashboard_container">
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#f27c1b"
                      title="Class Title"
                      entry={classTitle}
                      icon={<FaUserGraduate className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#633ccd"
                      title="Subjects"
                      entry={numOfSubjects}
                      icon={<FaBookReader className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#e65061"
                      title="Assessment"
                      entry={assessmentPercentage + "%"}
                      icon={<FaChartLine className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#98c38b"
                      title="Attendance"
                      entry="N/A"
                      icon={<FaChartArea className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader title="Student Bio-Data" />
                      <div className="dashboard_school_list">
                        <div className="avatar-container">
                          <div className="avatar-box">
                            <Avatar
                              label={lastNameInitial + firstNameInitial}
                              shape="circle"
                              style={{ width: 150, height: 150, fontSize: 70 }}
                              className="p-overlay-badge"
                            >
                              <Badge
                                style={{
                                  position: "absolute",
                                  top: 22,
                                  right: 22,
                                  width: 15,
                                  height: 15,
                                  backgroundColor: active
                                    ? colors.success
                                    : colors.danger,
                                }}
                                className="avatar-badge"
                              />
                            </Avatar>
                          </div>
                          <div className="information-container">
                            <div
                              style={{
                                marginTop: 50,
                                fontSize: 20,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              <strong>Personal Information</strong>
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">First Name: </strong>
                              <span>{firstName}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Last Name: </strong>
                              <span>{lastName}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Middle Name: </strong>
                              <span>{middleName}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Gender: </strong>
                              <span>{gender}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Title: </strong>
                              <span>{title}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Address: </strong>
                              <span>{address}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                State of Residence:{" "}
                              </strong>
                              <span>{stateOfResidence}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                L.G.A of Residence:{" "}
                              </strong>
                              <span>{lgaOfResidence}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                State of Origin:{" "}
                              </strong>
                              <span>{stateOfOrigin}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                L.G.A of Origin:{" "}
                              </strong>
                              <span>{lgaOfOrigin}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Nationality: </strong>
                              <span>{nationality}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Religion: </strong>
                              <span>{religion}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Date of Birth: </strong>
                              <span>{dateOfBirth}</span>
                            </p>
                            <div
                              style={{
                                marginTop: 30,
                                fontSize: 20,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              <strong>Contact Information</strong>
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Phone Number 1:{" "}
                              </strong>
                              <span>{phoneNumber}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Email: </strong>
                              <span>{email}</span>
                            </p>
                            <div
                              style={{
                                marginTop: 30,
                                fontSize: 20,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              <strong>Academic Information</strong>
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Previous School:{" "}
                              </strong>
                              <span>{previousSchool}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  addStyle="student_list_box"
                />
              </div>
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              <div className="main_right_boxes">
                <Card
                  children={
                    <>
                      <MainHeader title="Class Subjects" />
                      <div className="mt-10">
                        {assignedSubjects.map((item) => {
                          return (
                            <p
                              key={
                                !empty(item) && !empty(item._id) ? item._id : 0
                              }
                              className="mb-10 flex space-between bio-data-item"
                            >
                              <span>
                                <FaStar
                                  color="#DAA520"
                                  style={{ marginRight: 8, fontSize: 12 }}
                                />
                                {!empty(item) && !empty(item.title)
                                  ? item.title
                                  : ""}
                              </span>
                            </p>
                          );
                        })}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
              </div>
            </div>
            {/* end of right box */}
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default StudentsProfile;
