import client from "./Client";

const getStaffData = (userId, token) =>
  client.get(`/staff/details/${encodeURIComponent(userId)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

export default {
  getStaffData,
};
