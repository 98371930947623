import React from "react";

import "./ErrorPages.css";

function NotFound() {
  return (
    <div className="not-found">
      <p className="not-found-title">Error 404</p>
      <p className="not-found-content">
        The page or resource requested was not found
      </p>
    </div>
  );
}

export default NotFound;
