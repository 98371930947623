import "./ButtonIcon.css";
import Button from "@mui/material/Button";
import { FaListUl } from "react-icons/fa";

function ButtonIcon({
  backgroundColor,
  borderColor,
  color,
  icon,
  marginTop,
  buttonText,
  width,
  height,
  onClick,
  type,
  marginRight,
  fontSize = 14,
  textSize,
}) {
  return (
    <Button
      type={type}
      sx={{
        backgroundColor,
        color,
        width,
        borderColor,
        marginTop,
        height,
        marginRight,
        "&:hover": { backgroundColor, opacity: 0.7 },
      }}
      variant="outlined"
      onClick={onClick}
    >
      {buttonText === "View all" ? (
        <FaListUl style={{ fontSize }} />
      ) : (
        icon && icon
      )}
      <span style={{ fontSize: textSize }}>{buttonText}</span>
    </Button>
  );
}

export default ButtonIcon;
