import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";

// api
import classApi from "../../api/Classes";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import StatCard from "../../components/statcard/StatCard";
import DoughnutChart from "../../components/chart/doughnut/DoughnutChart";
import {
  FaBookReader,
  FaChartArea,
  FaChartLine,
  FaExclamationTriangle,
  FaUserGraduate,
} from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import BoxLoading from "../../components/skeleton/BoxLoading";
import TableLoading from "../../components/skeleton/TableLoading";
import FullPageLoader from "../../components/loader/FullPageLoader";

const ClassProfile = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const [classData, setClassData] = useState({});
  const [numOfStudents, setNumOfStudents] = useState(0);
  const [numOfSubjects, setNumOfSubjects] = useState(0);
  const [students, setStudents] = useState([]);
  const [genderCounts, setGenderCounts] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [sessionId, setSessionId] = useState(
    !empty(user) && !empty(user.currentSessionId) ? user.currentSessionId : ""
  ); // for stats if selected (WIP)
  const [termId, setTermId] = useState(
    !empty(user) && !empty(user.currentTermId) ? user.currentTermId : ""
  ); // for stats if selected (WIP)
  const [assessmentPercentage, setAssessmentPercentage] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const [studentStatLoading, setStudentStatLoading] = useState(false);
  const [subjectStatLoading, setSubjectStatLoading] = useState(false);
  const [assessmentStatLoading, setAssessmentStatLoading] = useState(false);
  const [attendanceStatLoading, setAttendanceStatLoading] = useState(false);
  const [subjectsInClassLoading, setSubjectsInClassLoading] = useState(false);
  const [studentsByGenderLoading, setStudentByGenderLoading] = useState(false);
  const [studentPerformanceLoading, setStudentPerformanceLoading] =
    useState(false);
  const { classId } = useParams();
  const toastTR = useRef(null);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    if (!classId) {
      navigate("/404");
    } else {
      checkAuthorization();
    }
  }, [classId, user]);

  useEffect(() => {
    if (isAuthorized) {
      getNumberOfStudentsByGender();
      getClassAssessmentPercentage();
      getNumOfSubjects();
      getNumOfStudents();
      getClassDetails();
      getSubjectsInClass();
      getStudentsInClassPerformance();
    }
  }, [isAuthorized, classId, user]);

  const checkAuthorization = () => {
    // check if user is authorized to view class details
    const staffClasses =
      !empty(user) && !empty(user.classes) ? user.classes : [];
    if (
      !empty(staffClasses) &&
      (staffClasses.includes("All") || staffClasses.includes(classId))
    ) {
      setIsAuthorized(true);
    }
  };

  const getClassDetails = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSingleClass(classId, schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data) ||
        !response_data.response
      ) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      setClassData(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {}
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjectsInClass = async () => {
    try {
      if (!subjectsInClassLoading) setSubjectsInClassLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const limit = 6;
      const response = await classApi.getSubjectsInClass(
        classId,
        schoolId,
        limit,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        return setSubjects([]);
      }
      setSubjects(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : []
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setSubjectsInClassLoading(false);
    }
  };

  const getNumOfStudents = async () => {
    try {
      if (!studentStatLoading) setStudentStatLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getNumOfStudents(
        classId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setNumOfStudents(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Number of Students", "Something went wrong.");
    } finally {
      setStudentStatLoading(false);
    }
  };

  const getNumOfSubjects = async () => {
    if (!subjectStatLoading) setSubjectStatLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getNumOfClassSubjects(
        classId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setNumOfSubjects(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Number of Subjects", "Something went wrong.");
    } finally {
      setSubjectStatLoading(false);
    }
  };

  const getClassAssessmentPercentage = async () => {
    if (!assessmentStatLoading) setAssessmentStatLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getClassAssessmentPercentage(
        classId,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setAssessmentPercentage(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Number of Subjects", "Something went wrong.");
    } finally {
      setAssessmentStatLoading(false);
    }
  };

  const getStudentsInClassPerformance = async () => {
    try {
      if (!studentPerformanceLoading) setStudentPerformanceLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const limit = 10;
      const response = await classApi.getStudentsInClassPerformance(
        classId,
        schoolId,
        termId,
        sessionId,
        limit,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setStudents(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : []
      );
    } catch (error) {
      responseDailog("error", "Students", "Something went wrong.");
    } finally {
      setStudentPerformanceLoading(false);
    }
  };

  const getNumberOfStudentsByGender = async () => {
    try {
      if (!studentsByGenderLoading) setStudentByGenderLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getNumberOfStudentsByGender(
        classId,
        schoolId,
        termId,
        sessionId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setGenderCounts(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {}
      );
    } catch (error) {
      responseDailog("error", "Students", "Something went wrong.");
    } finally {
      setStudentByGenderLoading(false);
    }
  };

  const title =
    !empty(classData) && !empty(classData.title) ? classData.title : "";
  const active =
    !empty(classData) && !empty(classData.active) ? classData.active : "";

  const performanceBodyTemplate = (rowData) => {
    const score =
      !empty(rowData) && !empty(rowData.performanceScore)
        ? rowData.performanceScore
        : 0;

    let score_tag = "";
    if (score >= 70) {
      score_tag = <span style={{ color: "green" }}>{score}%</span>;
    } else if (score >= 60 && score <= 69.9) {
      score_tag = <span style={{ color: "#633ccd" }}>{score}%</span>;
    } else if (score >= 50 && score <= 59.9) {
      score_tag = <span style={{ color: "orange" }}>{score}%</span>;
    } else if (score >= 45 && score <= 49.9) {
      score_tag = <span style={{ color: "gray" }}>{score}%</span>;
    } else {
      score_tag = <span style={{ color: "red" }}>{score}%</span>;
    }

    return score_tag;
  };

  const goToPositioning = () => {
    navigate("/assessment/subjects", {
      state: { sessionId, termId, classId },
    });
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {isAuthorized ? (
            <>
              {/* menu header */}
              <MainHeader
                title={title + " Profile"}
                children={
                  <>
                    <ButtonIcon
                      borderColor="#633ccd"
                      backgroundColor="transparent"
                      color="#633ccd"
                      buttonText="Assessment"
                      width={120}
                      marginRight={1}
                      height={30}
                      fontSize={14}
                      onClick={() => navigate(`/assessment/set`)}
                    />
                    <ButtonIcon
                      borderColor="#389d17"
                      backgroundColor="transparent"
                      color="#389d17"
                      buttonText="Students"
                      width={110}
                      height={30}
                      marginRight={1}
                      fontSize={14}
                      onClick={() => navigate(`/students/class/${classId}`)}
                    />
                    <ButtonIcon
                      borderColor="#ce1616"
                      backgroundColor="transparent"
                      color="#ce1616"
                      buttonText="Position"
                      width={110}
                      height={30}
                      fontSize={14}
                      onClick={() => goToPositioning()}
                    />
                  </>
                }
              />
              {/* end menu header */}
              <div className="mt-10 dashboard_container">
                {/* left box */}
                <div id="main_left_box">
                  {/* stat box */}
                  <div className="top_stat_box">
                    <Card
                      children={
                        !studentStatLoading ? (
                          <StatCard
                            underlineWidth={15}
                            bgColor="#f27c1b"
                            title="Students"
                            entry={numOfStudents}
                            icon={<FaUserGraduate className="stat_card_icon" />}
                          />
                        ) : (
                          <BoxLoading />
                        )
                      }
                      addStyle="card_adjust_stat"
                    />
                    <Card
                      children={
                        !subjectStatLoading ? (
                          <StatCard
                            underlineWidth={15}
                            bgColor="#633ccd"
                            title="Subjects"
                            entry={numOfSubjects}
                            icon={<FaBookReader className="stat_card_icon" />}
                          />
                        ) : (
                          <BoxLoading />
                        )
                      }
                      addStyle="card_adjust_stat"
                    />
                    <Card
                      children={
                        !assessmentStatLoading ? (
                          <StatCard
                            underlineWidth={15}
                            bgColor="#e65061"
                            title="Assessment"
                            entry={assessmentPercentage + "%"}
                            icon={<FaChartLine className="stat_card_icon" />}
                          />
                        ) : (
                          <BoxLoading />
                        )
                      }
                      addStyle="card_adjust_stat"
                    />
                    <Card
                      children={
                        <StatCard
                          underlineWidth={15}
                          bgColor="#98c38b"
                          title="Attendance"
                          entry="N/A"
                          icon={<FaChartArea className="stat_card_icon" />}
                        />
                      }
                      addStyle="card_adjust_stat"
                    />
                  </div>
                  {/* stat header end */}

                  {/* chart */}
                  <div className="inner_chart_box">
                    <Card
                      children={
                        <>
                          <MainHeader title="Students Stat By Gender" />
                          {!studentsByGenderLoading ? (
                            <DoughnutChart
                              doughnutData={[
                                !empty(genderCounts) &&
                                !empty(genderCounts.male)
                                  ? genderCounts.male
                                  : 0,
                                !empty(genderCounts) &&
                                !empty(genderCounts.female)
                                  ? genderCounts.female
                                  : 0,
                              ]}
                            />
                          ) : (
                            <BoxLoading
                              bodyHeight="400px"
                              headerHeight="80px"
                              marginTop={-80}
                            />
                          )}
                        </>
                      }
                      addStyle="px-20"
                      id="chart_box1"
                    />
                    <Card
                      children={
                        <>
                          <MainHeader title="Subjects" />
                          {!subjectsInClassLoading ? (
                            <DataTable
                              value={subjects}
                              tableStyle={{ minWidth: "10rem" }}
                            >
                              <Column field="code" header="Code"></Column>
                              <Column field="title" header="Title"></Column>
                            </DataTable>
                          ) : (
                            <TableLoading rows={6} cols={2} />
                          )}
                        </>
                      }
                      id="chart_box2"
                    />
                  </div>
                  <div className="chart_box">
                    <Card
                      children={
                        <>
                          <MainHeader
                            title="Top 10 Students"
                            button
                            buttonHeight={25}
                            buttonText={
                              <NavLink
                                style={{
                                  color: "#ffffff",
                                  textDecoration: "none",
                                }}
                                to={`/class/${classId}/students/performance`}
                              >
                                more
                              </NavLink>
                            }
                          />
                          <div className="dashboard_school_list">
                            {!studentPerformanceLoading ? (
                              <DataTable
                                value={students}
                                tableStyle={{ minWidth: "30rem" }}
                              >
                                <Column
                                  field="regNo"
                                  header="Reg. No."
                                ></Column>
                                <Column
                                  field="fullName"
                                  header="Full Name"
                                ></Column>
                                <Column
                                  field="performanceScore"
                                  header="Performance"
                                  body={performanceBodyTemplate}
                                ></Column>
                              </DataTable>
                            ) : (
                              <TableLoading rows={10} cols={3} />
                            )}
                          </div>
                        </>
                      }
                      addStyle="student_list_box"
                    />
                  </div>
                  {/* end chart */}

                  {/*  */}
                </div>
                {/* end of left box */}

                {/* right box */}
                <div id="main_right_box">
                  <div className="main_right_boxes">
                    {/* activities */}
                    <Card
                      children={
                        <>
                          <MainHeader
                            title="Activities"
                            button
                            buttonHeight={25}
                            buttonText={
                              <NavLink
                                to=""
                                style={{
                                  color: "#ffffff",
                                  textDecoration: "none",
                                }}
                              >
                                more
                              </NavLink>
                            }
                          />
                          {/* {ActivityData.map((activity) => {
                            return (
                              <ListTitleSubtitle
                                key={activity.id}
                                date={activity.date}
                                time={activity.time}
                                title={activity.title}
                                subtitle={activity.subtitle}
                                borderColor={activity.color}
                              />
                            );
                          })} */}
                        </>
                      }
                      addStyle="card_adjust"
                    />
                    {/* end of activities */}
                  </div>
                </div>
                {/* end of right box */}
              </div>
            </>
          ) : (
            <div className="inner-unauthorized">
              <FaExclamationTriangle color="red" size={30} />
              <p>
                You do not have the required authorization to view this page.
              </p>
              <span>
                Go back to{" "}
                <strong>
                  <NavLink to="/classes">Class List</NavLink>
                </strong>
              </span>
            </div>
          )}
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default ClassProfile;
