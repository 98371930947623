import Calendar from 'react-calendar';
import './AppCalendar.css';

const AppCalendar = ({ ...otherProps }) => {
  return (
    <Calendar 
      {...otherProps}
    />
  )
}

export default AppCalendar;