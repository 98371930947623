import { createContext, useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getStaffDetails } from "../../api/GetStaffDetails";
import { empty } from "../../Utilities/utils";
import { ProgressSpinner } from "primereact/progressspinner";

export const AuthContext = createContext();

export const ProtectedRoute = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const staffDetails = await getStaffDetails();
      if (
        !empty(staffDetails) &&
        staffDetails.success &&
        !empty(staffDetails.response) &&
        !empty(staffDetails.storedToken)
      ) {
        setToken(staffDetails.storedToken);
        setUser(staffDetails.response);
        return setIsLoggedIn(true);
      } else {
        setUser({});
        return setIsLoggedIn(false);
      }
    };
    getUser();
  }, []);

  if (user === null) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "rgba(255,255,255,0.7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ProgressSpinner
          style={{ width: 50, height: 50 }}
          strokeWidth={9}
          animationDuration={0.5}
        />
      </div>
    );
  }

  return user && !empty(user.userType) && user.userType === "STAFF" ? (
    <AuthContext.Provider
      value={{ user, setUser, isLoggedIn, setIsLoggedIn, token, setToken }}
    >
      <Outlet />
    </AuthContext.Provider>
  ) : (
    <Navigate to="/signin" />
  );
};

export default ProtectedRoute;
