import "./StatCard.css";

const StatCard = ({
  id,
  bgColor,
  title,
  entry,
  underlineWidth,
  icon,
  entrySize = 25,
}) => {
  return (
    <div className="stat_card">
      <div className="stat_card_top_box">
        <div className="stat_card_icon_box" style={{ background: bgColor }}>
          {icon}
        </div>
        <span className="stat_card_title">{title}</span>
      </div>
      <span className="stat_card_entry" style={{ fontSize: entrySize }}>
        {entry}
      </span>
      <div
        className="stat_card_underline"
        style={{ background: bgColor, width: underlineWidth }}
      ></div>
    </div>
  );
};

export default StatCard;
