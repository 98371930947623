import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "./Students.css";

// api
import studentApi from "../../api/Student";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import StudentsTable from "../../components/tables/primeTable/students/StudentsTable";
import TableLoading from "../../components/skeleton/TableLoading";

const Student = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const getValues = useParams();
  const classId =
    !empty(getValues) && !empty(getValues.classId) ? getValues.classId : "";
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [studentData, setStudentData] = useState([]);
  const [studentToToggleDialog, setStudentToToggleDialog] = useState(false);
  const [selectedStudentDetail, setSelectedStudentDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [search, setSearch] = useState("");

  useEffect(() => {
    // fetch student
    try {
      getStudents();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load student. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { studentAdded: false, studentUpdated: false },
        });
      }
    }
  }, [first, rows, search, classId]);

  // function to get all student
  const getStudents = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const page = first / rows;
      let response;
      if (!empty(classId)) {
        response = await studentApi.getStudentsInClass(
          schoolId,
          page,
          rows,
          search,
          classId,
          "list",
          token
        );
      } else {
        response = await studentApi.getStudents(
          schoolId,
          page,
          rows,
          search,
          "list",
          token
        );
      }
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudentData([]);
      } else {
        setStudentData(response_data.response);
        setTotalRecords(!empty(response_data.count) ? response_data.count : 0);
      }

      // check if a new student was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const studentAdded = !empty(paramState.studentAdded)
          ? paramState.studentAdded
          : false;
        const studentUpdated = !empty(paramState.studentUpdated)
          ? paramState.studentUpdated
          : false;
        if (studentAdded === true || studentUpdated === true) {
          const actionType = studentAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Student ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { studentAdded: false, studentUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  //function to toggle student visibility
  const toggleStudentVisiblity = async () => {
    try {
      if (studentToToggleDialog) setStudentToToggleDialog(false);
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const active =
        !empty(selectedStudentDetail) && !empty(selectedStudentDetail.active)
          ? "Yes"
          : "No";
      const studentId =
        !empty(selectedStudentDetail) && !empty(selectedStudentDetail._id)
          ? selectedStudentDetail._id
          : "";
      const response = await studentApi.toggleStudentVisibility(
        studentId,
        active,
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to ${
                !empty(selectedStudentDetail) &&
                !empty(selectedStudentDetail.active) &&
                selectedStudentDetail.active === false
                  ? "enable"
                  : "disable"
              } student with name, ${
                !empty(selectedStudentDetail.lastName) &&
                !empty(selectedStudentDetail.firstName)
                  ? `${selectedStudentDetail.lastName} ${selectedStudentDetail.firstName}`
                  : ""
              }`;
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Student, ${
          !empty(selectedStudentDetail.lastName) &&
          !empty(selectedStudentDetail.firstName)
            ? `${selectedStudentDetail.lastName} ${selectedStudentDetail.firstName}`
            : ""
        }, was ${
          !empty(selectedStudentDetail) &&
          !empty(selectedStudentDetail.active) &&
          selectedStudentDetail.active === true
            ? "disabled"
            : "enabled"
        } successfully!`
      );
      return getStudents();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsLoading(false);
    }
  };

  //function to hide confirm modal
  const hideDisableStudentDialog = () => {
    setStudentToToggleDialog(false);
  };

  const disableStudentDialogFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideDisableStudentDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={toggleStudentVisiblity}
      />
    </div>
  );

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setFirst(0);
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader title="Students" />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <StudentsTable
                students={studentData}
                onPageChange={onPageChange}
                loading={isLoading}
                rows={rows}
                totalRecords={totalRecords}
                first={first}
                search={search}
                onSearchChange={onSearchChange}
              />
            ) : (
              <TableLoading />
            )}
            {/* table end  */}
          </div>
        </main>
      </AppWrapper>
      <Dialog
        visible={studentToToggleDialog}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={disableStudentDialogFooter}
        onHide={hideDisableStudentDialog}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to{" "}
              {!empty(selectedStudentDetail) &&
              !empty(selectedStudentDetail.active) &&
              selectedStudentDetail.active ? (
                <span style={{ color: "red" }}>
                  <strong>disable</strong>
                </span>
              ) : (
                <span style={{ color: "green" }}>
                  <strong>enable</strong>
                </span>
              )}{" "}
              the selected student -{" "}
              <strong>
                {!empty(selectedStudentDetail) &&
                !empty(selectedStudentDetail.title)
                  ? selectedStudentDetail.title
                  : ""}
              </strong>
              {selectedStudentDetail && (
                <b>
                  {!empty(selectedStudentDetail.name)
                    ? selectedStudentDetail.name
                    : ""}
                </b>
              )}
              ?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default Student;
