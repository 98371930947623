import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import sessionApi from "../../api/Session";
import termApi from "../../api/Terms";
import classApi from "../../api/Classes";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { AuthContext } from "../Root/ProtectedRoute";
import { FaExclamationTriangle } from "react-icons/fa";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  class_id: Yup.string().required(required),
  subject_id: Yup.string().required(required),
});

const initialValues = {
  subject_id: "",
  class_id: "",
};

const SetAssessment = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [classes, setClasses] = useState([]);
  const [terms, setTerms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [isClassSelected, setIsClassSelected] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState("");
  const toastTR = useRef(null);
  const [staffSubjects, setStaffSubjects] = useState(
    !empty(user) && !empty(user.subjects) ? user.subjects : []
  );
  const [staffClasses, setStaffClasses] = useState(
    !empty(user) && !empty(user.classes) ? user.classes : []
  );

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 20000,
    });
  };

  useEffect(() => {
    getAuthorization();
  }, [staffClasses, staffSubjects]);

  useEffect(() => {
    if (isAuthorized) {
      getClasses();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (isClassSelected) {
      getSubjects();
    }
  }, [isClassSelected]);

  const getAuthorization = () => {
    if (empty(staffClasses) && empty(staffSubjects)) {
      setIsAuthorized(false);
    } else {
      setIsAuthorized(true);
    }
  };

  const getClasses = async () => {
    setIsLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getClasses(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Failed to fetch classes!"
            : "Failed to fetch classes"
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        const classData = response_data.response;
        setClasses(classData);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjects = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSubjectsInClass(
        selectedClassId,
        schoolId,
        1000,
        token
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        setSubjects([]);
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "You are not authorized to record assessment for any subject in this class! You can request for authorization from your administrator."
            : "You are not authorized to record assessment for any subject in  this class! You can request for authorization from your administrator"
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        const subjectData = response_data.response;
        const isSelectedClass = staffClasses.includes(selectedClassId);
        if (empty(staffClasses) || !isSelectedClass) {
          const availableSubjects = subjectData.filter((item) =>
            staffSubjects.includes(item._id)
          );
          setSubjects(availableSubjects);
        } else {
          setSubjects(subjectData);
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const classId =
        !empty(values) && !empty(values.class_id) ? values.class_id : "";
      const subjectId =
        !empty(values) && !empty(values.subject_id) ? values.subject_id : "";
      navigate("/assessment/record", {
        state: { classId, subjectId },
      });
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClassChange = (setFieldValue) => (e) => {
    const { value } = e.target;
    setSubjects([]);
    setIsClassSelected(value);
    setSelectedClassId(value);
    setFieldValue("class_id", value);
  };

  const handleSubjectChange = (setFieldValue) => (e) => {
    const { value } = e.target;
    setFieldValue("subject_id", value);
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {isAuthorized ? (
            <div className="container flex-center-top">
              <MainHeader title="Set Assessment" />
              <div className="form-container mt-10">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form style={{ width: "100%" }}>
                      <div>
                        <em>
                          <strong>Note: *</strong> Enter the details for the
                          assessment you wish to set.
                        </em>
                      </div>
                      <div className="app_input_group">
                        <SelectField
                          labelTitle={
                            <>
                              Class <span className="required">*</span>
                            </>
                          }
                          placeholder="Select Class"
                          name="class_id"
                          options={classes}
                          height={50}
                          valueKey="id"
                          selectedOption={values.class_id}
                          handleChangeFunc={handleClassChange(setFieldValue)}
                        />
                        <SelectField
                          labelTitle={
                            <>
                              Subject <span className="required">*</span>
                            </>
                          }
                          placeholder="Select Subject"
                          name="subject_id"
                          options={subjects}
                          height={50}
                          valueKey="id"
                          selectedOption={values.subject_id}
                          handleChangeFunc={handleSubjectChange(setFieldValue)}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ButtonIcon
                          height={45}
                          marginTop={5}
                          color="#ffffff"
                          backgroundColor="#633ccd"
                          width={300}
                          borderColor="#633ccd"
                          buttonText="Start Assessment"
                          type="submit"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <div className="inner-unauthorized">
              <FaExclamationTriangle color="red" size={30} />
              <p>
                You do not have the required authorization to view this page.
              </p>
            </div>
          )}
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default SetAssessment;
