import React, { useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "../schools/SchoolsTable.css";
import { empty } from "../../../../Utilities/utils";
import { useNavigate } from "react-router-dom";

export default function StudentsTable({
  students,
  getStudentReportScreen,
  onPageChange,
  loading,
  rows = 50,
  totalRecords,
  first,
  search = "",
  onSearchChange,
  assessment = false,
  openAssessmentModal,
  recordScores = false,
  reportSheet = false,
}) {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedStudent, setSelectedStudent] = useState(null);

  const getSeverity = (status) => {
    switch (status) {
      case false:
        return "danger";

      case true:
        return "success";
      default:
        return "danger";
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          !empty(rowData) && !empty(rowData.active) ? "ACTIVE" : "IN-ACTIVE"
        }
        severity={getSeverity(
          !empty(rowData) && !empty(rowData.active) ? rowData.active : false
        )}
      />
    );
  };

  // go to profile
  const goToProfile = (rowData) => {
    const studentId = !empty(rowData) && !empty(rowData._id) ? rowData._id : "";
    navigate(`/student/${studentId}/profile`);
  };

  const renderHeader = () => {
    return (
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={search}
          onChange={onSearchChange}
          placeholder="Student Search"
        />
      </span>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div style={{ display: "flex", gap: 8 }}>
        {assessment ? (
          <>
            {recordScores && (
              <Button
                icon="pi pi-cloud-upload"
                style={{
                  width: 35,
                  height: 35,
                  backgroundColor: "transparent",
                  color: "#22C55E",
                  borderColor: "#22C55E",
                  borderWidth: 1,
                  borderRadius: "50%",
                }}
                onClick={() => openAssessmentModal(rowData)}
              />
            )}
            {reportSheet && (
              <Button
                icon="pi pi-id-card"
                style={{
                  width: 35,
                  height: 35,
                  backgroundColor: "transparent",
                  color: "#6366F1",
                  borderColor: "#6366F1",
                  borderWidth: 1,
                  borderRadius: "50%",
                }}
                onClick={() => getStudentReportScreen(rowData)}
              />
            )}
          </>
        ) : (
          <>
            <Button
              icon="pi pi-eye"
              style={{
                width: 35,
                height: 35,
                backgroundColor: "transparent",
                color: "#22C55E",
                borderColor: "#22C55E",
                borderWidth: 1,
                borderRadius: "50%",
              }}
              onClick={() => goToProfile(rowData)}
            />
          </>
        )}
      </div>
    );
  };
  const skeletonTemplate = () => {
    return <Skeleton width="100%" height="1.4rem" />;
  };
  const header = renderHeader();

  return (
    <div className="datatable">
      <DataTable
        value={students}
        paginator
        rows={rows}
        header={header}
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        selection={selectedStudent}
        onSelectionChange={(e) => setSelectedStudent(e.value)}
        selectionMode="single"
        dataKey="_id"
        stateStorage="session"
        stateKey="dt-state-demo-local"
        emptyMessage="No students found."
        tableStyle={{ minWidth: "50rem" }}
        onPage={onPageChange}
        lazy={true}
        totalRecords={totalRecords}
        first={first}
      >
        <Column
          field="regNo"
          header="Reg. No."
          sortable
          filterPlaceholder="Search"
          style={{ width: "5%" }}
          body={loading ? skeletonTemplate : (rowData) => rowData.regNo}
        ></Column>
        <Column
          field="lastName"
          header="Last Name"
          sortable
          filterPlaceholder="Search"
          style={{ width: "8%" }}
          body={loading ? skeletonTemplate : (rowData) => rowData.lastName}
        ></Column>
        <Column
          field="firstName"
          header="First Name"
          sortable
          filterPlaceholder="Search"
          style={{ width: "8%" }}
          body={loading ? skeletonTemplate : (rowData) => rowData.firstName}
        ></Column>
        <Column
          field="middleName"
          header="Middle Name"
          sortable
          filterPlaceholder="Search"
          style={{ width: "10%" }}
          body={loading ? skeletonTemplate : (rowData) => rowData.middleName}
        ></Column>
        {!recordScores && (
          <Column
            field="class"
            header="Class"
            sortable
            filterPlaceholder="Search"
            style={{ width: "3%" }}
            body={loading ? skeletonTemplate : (rowData) => rowData.class}
          ></Column>
        )}
        <Column
          field="status"
          header="Status"
          body={loading ? skeletonTemplate : statusBodyTemplate}
          sortable
          filterMenuStyle={{ width: "14rem" }}
          style={{ width: "10%" }}
        ></Column>
        <Column
          header="Action"
          body={loading ? skeletonTemplate : actionBodyTemplate}
          exportable={false}
          style={{ width: "10%" }}
        ></Column>
      </DataTable>
    </div>
  );
}
