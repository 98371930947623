import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import subjectApi from "../../api/Subject";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import SubjectTable from "../../components/tables/primeTable/subject/SubjectTable";

const Subjects = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [subjectData, setSubjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // fetch subject
    try {
      getSubjects();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load subjects. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { subjectAdded: false, subjectUpdated: false },
        });
      }
    }
  }, []);

  // function to get subjects
  const getSubjects = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await subjectApi.getSubjects(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setSubjectData([]);
      } else {
        setSubjectData(response_data.response);
      }

      // check if a new subject was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const subjectAdded = !empty(paramState.subjectAdded)
          ? paramState.subjectAdded
          : false;
        const subjectUpdated = !empty(paramState.subjectUpdated)
          ? paramState.subjectUpdated
          : false;
        if (subjectAdded === true || subjectUpdated === true) {
          const actionType = subjectAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Subject ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { subjectAdded: false, subjectUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader title="Subject" />
            {/* end of header */}

            {/* table start */}
            <SubjectTable subjects={subjectData} />
            {/* table end  */}
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
      </AppWrapper>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default Subjects;
