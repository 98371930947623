import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import classApi from "../../api/Classes";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import ClassesTable from "../../components/tables/primeTable/classes/ClassesTable";
import TableLoading from "../../components/skeleton/TableLoading";

const SchoolClasses = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [classData, setClassData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // fetch classes
    try {
      getClasses();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load classes. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { classAdded: false, classUpdated: false },
        });
      }
    }
  }, []);

  // function to get all classes
  const getClasses = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getClasses(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClassData([]);
      } else {
        setClassData(response_data.response);
      }

      // check if a new class was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const classAdded = !empty(paramState.classAdded)
          ? paramState.classAdded
          : false;
        const classUpdated = !empty(paramState.classUpdated)
          ? paramState.classUpdated
          : false;
        if (classAdded === true || classUpdated === true) {
          const actionType = classAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Class ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { classAdded: false, classUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader title="Class" />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <ClassesTable classes={classData} />
            ) : (
              <TableLoading />
            )}
            {/* table end  */}
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
      </AppWrapper>

      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default SchoolClasses;
