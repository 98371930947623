import React from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";

function AppWrapper({ children, sidebarOpen, toggleSidebar }) {
  return (
    <>
      <Navbar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {children}
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
    </>
  );
}

export default AppWrapper;
