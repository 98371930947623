import React from "react";

export default function Underline({ width = 40, height = 6 }) {
  return (
    <div
      style={{
        width,
        height,
        borderRadius: 12,
        backgroundColor: "#633ccd",
      }}
    ></div>
  );
}
