import client from "./Client";

const getStaff = (schoolId, token) =>
  client.get(`/staff/school/${schoolId}/staff`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getSingleStaff = (staffId, schoolId, token) =>
  client.get(`/${schoolId}/staff/${staffId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getStaffProfile = (staffId, schoolId, token) =>
  client.get(`/staff/school/${schoolId}/staff/${staffId}/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const updatePassword = (
  staff_id = "",
  current_password = "",
  new_password = "",
  confirm_password = "",
  token
) =>
  client.post(
    `/staff/settings/password/update`,
    {
      staff_id,
      current_password,
      new_password,
      confirm_password,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

const getStudentAssessmentPerformance = (school_id, token) =>
  client.get(`/staff/school/${school_id}/stat/students/assessment`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getSchoolAssessmentsData = (school_id, token) =>
  client.get(`/staff/school/${school_id}/classes/students/assessments-data`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

export default {
  getStaff,
  getSingleStaff,
  getStaffProfile,
  updatePassword,
  getStudentAssessmentPerformance,
  getSchoolAssessmentsData,
};
