import { Skeleton } from "@mui/material";
import React from "react";

function BoxLoading({ bodyHeight = "40px", headerHeight = "20px", marginTop }) {
  return (
    <div>
      <Skeleton width="100%" height={headerHeight}></Skeleton>
      <Skeleton
        width="100%"
        height={bodyHeight}
        style={{ marginTop }}
      ></Skeleton>
    </div>
  );
}

export default BoxLoading;
