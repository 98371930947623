import { useContext } from "react";
import {
  FaBars,
  FaRegBell,
  FaCaretDown,
  FaRegEnvelope,
  FaTimes,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { API_BASE_URL } from "../../config/config";
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty } from "../../Utilities/utils";

// css
import "./Navbar.css";

// images
import appLogo from "../../assets/logo/blue-logo.png";

const Navbar = ({ sidebarOpen, toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const firstName =
    !empty(user) && !empty(user.firstName) ? user.firstName : "NA";
  const schoolName =
    !empty(user) && !empty(user.schoolName) ? user.schoolName : "";
  const schoolAvatar =
    !empty(user) && !empty(user.schoolAvatar) ? user.schoolAvatar : "";
  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={toggleSidebar}>
        {!sidebarOpen ? (
          <FaBars className="menu_bar" />
        ) : (
          <FaTimes className="menu_bar" />
        )}
      </div>
      <div className="navbar_left">
        <span style={{ fontWeight: "bold", fontSize: 20 }}>{schoolName}</span>
      </div>
      <div className="navbar_right">
        <NavLink to="/inbox" style={{ textDecoration: "none" }} className="">
          <div className="icon_box">
            <FaRegEnvelope />
          </div>
        </NavLink>
        <NavLink
          to="/notification"
          style={{ textDecoration: "none" }}
          className=""
        >
          <div className="icon_box">
            <FaRegBell />
            <div></div>
          </div>
        </NavLink>
        <div className="navbar_avatar_box ">
          <NavLink to="/profile" style={{ textDecoration: "none" }}>
            <img
              className="navbar_avatar_box_img"
              src={!empty(schoolAvatar) ? API_BASE_URL + schoolAvatar : appLogo}
              alt="avatar"
            />
          </NavLink>
          <span className="navbar_user_name">{firstName}</span>
          <span>
            <FaCaretDown />
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
