import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function LogOut() {
  const navigate = useNavigate();

  useEffect(() => {
    const staffToken = localStorage.getItem("staffToken");
    console.log(staffToken);
    if (staffToken) {
      localStorage.removeItem("staffToken");
    }
    navigate("/signin");
  }, []);

  return <div>Logged Out</div>;
}

export default LogOut;
