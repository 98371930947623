import client from "./Client";

const signIn = (email, password) =>
  client.post(
    "/staff/signin",
    {
      email,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const twoFactorAuthentication = (email, access_code) =>
  client.post(
    "/staff/authentication/two-factor",
    {
      email,
      access_code,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const resetPassword = (
  email,
  password,
  confirm_password,
  password_reset_hash
) =>
  client.post(
    "/staff/reset-password",
    {
      email,
      password,
      confirm_password,
      password_reset_hash,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const forgotPassword = (email) =>
  client.post(
    "/staff/forgot-password",
    { email },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const updatePassword = (email, password, confirmPassword, resetHash) =>
  client.post(
    "/staff/password/update-password",
    {
      email,
      password,
      confirm_password: confirmPassword,
      password_reset_hash: resetHash,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export default {
  signIn,
  twoFactorAuthentication,
  forgotPassword,
  updatePassword,
  resetPassword,
};
