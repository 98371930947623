import client from "./Client";

const getSessions = (schoolId, token) =>
  client.get(`/staff/school/${schoolId}/sessions`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getSession = (sessionId, schoolId, token) =>
  client.get(`/staff/school/${schoolId}/sessions/${sessionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

export default {
  getSession,
  getSessions,
};
