import { useContext, useRef, useState } from "react";
import {
  FaChartArea,
  FaChartLine,
  FaEye,
  FaUserFriends,
  FaUserTie,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

// api
import staffApi from "../../api/Staff";

//css
import "./Dashboard.css";

//components
import Card from "../../components/card/Card";
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import Barchart from "../../components/chart/barchart/Barchart";
import StatCard from "../../components/statcard/StatCard";
import AppCalendar from "../../components/calendar/AppCalendar";
import AppWrapper from "../../components/appWrapper/AppWrapper";

//data
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import { colors } from "../../Utilities/colors";

const Dashboard = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const [value, onChange] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isNoOfStudentLoading, setIsNoOfStudentLoading] = useState(false);
  const [noOfSubjectsInSchool, setNoOfSubjectsInSchool] = useState(0);
  const [noOfClasses, setNoOfClasses] = useState(0);
  const [studentPerformance, setStudentPerformance] = useState(0);

  const assessmentData = [];
  const [chartData, setChartData] = useState({
    labels: assessmentData.map((data) => {
      const classTitle = !empty(data) && !empty(data.title) ? data.title : "";
      return classTitle;
    }),
    datasets: [
      {
        label: "Class Performance",
        data: assessmentData.map((data) => {
          const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
          return sum;
        }),
        backgroundColor: "#6d4dc7d6",
        borderRadius: 12,
      },
    ],
  });
  const toastTR = useRef(null);

  useEffect(() => {
    // getNumOfStudents();
    // getNoOfSubjects();
    // getNoOfStaff();
    // getNoOfClasses();
    getSchoolAssessmentsData();
    // getNoOfStudentsByGender();
    // getNoOfStaffByGender();
    // getRecentlyRegisterdStudents();
  }, []);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getSchoolAssessmentsData = async () => {
    setIsNoOfStudentLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await staffApi.getSchoolAssessmentsData(schoolId, token);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data?.response
      ) {
        return;
      }
      const _assessmentData =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : [];
      setChartData({
        labels: _assessmentData.map((data) => {
          const classTitle =
            !empty(data) && !empty(data.title) ? data.title : "";
          return classTitle;
        }),
        datasets: [
          {
            label: "Class Performance",
            data: _assessmentData.map((data) => {
              const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
              return sum;
            }),
            backgroundColor: _assessmentData.map((data) => {
              const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
              let colorCode = "#6d4dc7d6";
              if (sum < 50) {
                colorCode = colors.danger;
              }
              return colorCode;
            }),
            borderRadius: 12,
          },
        ],
      });
    } catch (error) {
      console.log(error);
      responseDailog("error", "Assessment Data", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getNoOfSubjects = async () => {
    setIsNoOfStudentLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getNumberOfSubjectsInSchool(
        schoolId,
        token
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setNoOfSubjectsInSchool(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Number of Students", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getNoOfClasses = async () => {
    setIsNoOfStudentLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getNumberOfClasses(schoolId, token);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setNoOfClasses(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Number of Students", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Hello, Welcome Back!" leftIcon="&#128075; " />
          {/* end menu header */}
          <div className="mt-10 dashboard_container">
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#633ccd"
                      title="Subjects"
                      entry={noOfSubjectsInSchool}
                      icon={<FaUserTie className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#98c38b"
                      title="Classes"
                      entry={noOfClasses}
                      icon={<FaUserFriends className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#e65061"
                      title="Assessment"
                      entry={studentPerformance + "%"}
                      icon={<FaChartLine className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#98c38b"
                      title="Attendance"
                      entry="N/A"
                      icon={<FaChartArea className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader title="Class Performance" />
                      <Barchart data={chartData} />
                    </>
                  }
                  addStyle="barchart"
                />
              </div>
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              {/* calendar */}
              <Card
                children={
                  <AppCalendar
                    onChange={onChange}
                    value={value}
                    onClickDay={(value) => alert(value)}
                  />
                }
                addStyle="card_adjust"
              />

              {/* end of calendar */}
              <div className="main_right_boxes">
                {/* activities */}
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Activities"
                        button
                        buttonHeight={25}
                        buttonText="more"
                        link=""
                        textSize={12}
                      />
                      {/* {ActivityData.map((activity) => {
                        return (
                          <ListTitleSubtitle
                            key={activity.id}
                            date={activity.date}
                            time={activity.time}
                            title={activity.title}
                            subtitle={activity.subtitle}
                            borderColor={activity.color}
                          />
                        );
                      })} */}
                    </>
                  }
                  addStyle="card_adjust"
                />
                {/* end of activities */}
                {/* Top schools */}
                <Card
                  children={
                    <>
                      <MainHeader title="Subjects" />

                      {/* schools */}
                      {/* {schoolsData.map((school) => {
                        return (
                          <ListTitleSubtitle
                            key={school.id}
                            img={school.img}
                            title={school.title}
                            subtitle={school.subtitle + " Students"}
                          />
                        );
                      })} */}
                    </>
                  }
                  addStyle="card_adjust"
                />
                {/* end of top school */}
              </div>
            </div>
          </div>
          {/* end of right box */}
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default Dashboard;
