import "./MainHeader.css";
import ButtonIcon from "../../buttons/buttonIcon/ButtonIcon";
import { NavLink } from "react-router-dom";

const MainHeader = ({
  icon,
  rightIcon,
  leftIcon,
  title,
  button,
  buttonText,
  link,
  children,
  redirect = true,
  onClick,
  showButton = true,
  titleColor = "#000000",
  buttonHeight,
  buttonSize,
  textSize,
}) => {
  return (
    <div className="main_header">
      <div className="left_header_title">
        {leftIcon && <span className="main_header_icon">{leftIcon}</span>}
        <div className="main_header_text">
          <span style={{ color: titleColor }}>{title}</span>{" "}
          {rightIcon && <div className="header_right_icon">{rightIcon}</div>}
        </div>
      </div>
      {button && (
        <div className="header_button">
          {!redirect && showButton ? (
            <ButtonIcon
              height={buttonHeight}
              backgroundColor="#633ccd"
              color="#ffffff"
              icon={icon}
              buttonText={buttonText}
              onClick={onClick}
            />
          ) : (
            <NavLink to={link} style={{ textDecoration: "none" }}>
              <ButtonIcon
                height={buttonHeight}
                backgroundColor="#633ccd"
                color="#ffffff"
                icon={icon}
                buttonText={buttonText}
                fontSize={buttonSize}
                textSize={textSize}
              />
            </NavLink>
          )}
        </div>
      )}
      {children && <div className="header_button">{children}</div>}
    </div>
  );
};

export default MainHeader;
