import client from "./Client";

const getSubjects = (schoolId, token) =>
  client.get(`/staff/school/${schoolId}/subjects`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

const getSingleSubject = (subject_id, school_id, token) =>
  client.get(`/staff/school/${school_id}/subject/${subject_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

export default {
  getSubjects,
  getSingleSubject,
};
