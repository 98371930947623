import { token } from "./ReturnToken";
import { empty, prepareResponseData } from "../Utilities/utils";

//api
import generalApi from "./General";

export const getStaffDetails = async () => {
  try {
    const { decodedToken, storedToken } = await token();
    if (!empty(storedToken) && !empty(decodedToken)) {
      const staffId =
        !empty(decodedToken) && !empty(decodedToken._id)
          ? decodedToken._id
          : "";
      const response = await generalApi.getStaffData(staffId, storedToken);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || empty(response_data.success)) {
        const error = { success: false };
        if (!empty(response_data.logOut)) {
          error.logOut = response_data.logOut;
          error.response = response_data.meassage;
        }
        return error;
      }

      const userDetails = !empty(response_data.response)
        ? response_data.response
        : {};
      if (
        !empty(userDetails.tokenRefreshed) &&
        userDetails.tokenRefreshed &&
        !empty(userDetails.token)
      ) {
        localStorage.setItem("staffToken", userDetails.token);
      }

      return {
        success: true,
        response: userDetails,
        storedToken,
      };
    }

    return { success: false, response: "Something went wrong." };
  } catch (error) {
    return { success: false, response: "Something went wrong." };
  }
};
